<template>
	<div class="mypurse">
		<div class="mywallet-center-text">
			<el-row class="mywallet" style="">
				<!-- left_common -->
				<el-col :span="6">
					<contentLeft type="4" style='box-shadow: 0px 0px 6px #c4c4c4;'></contentLeft>
				</el-col>
				<el-col :span="18" class="content-right">
					<el-row class="con-base" type="flex" justify="space-between">
						<el-col :span="15">
							<b><span style="color: #c43e1b;">|</span>可用金币:<span style=" font-size: 22px;color:#fe8f00;">{{allgoldcoin}}</span>币</b>
							<b><span style="color: #c43e1b;">|</span>可提现金币:<span style=" font-size: 22px;color:#fe8f00;">{{goldcoin}}</span>币</b>
						</el-col>
						<el-col :span="3">
							<el-button type="primary" @click="chongzhi()">点击充值</el-button>
						</el-col>
						<el-col :span="3" v-show="tixian_isshow">
							<el-button type="primary" @click="tixian_btn">提现</el-button>
						</el-col>
						<el-col :span="3" v-show="tixian_isshow">
							<el-button type="primary" @click="InvoiceApplication">发票申请</el-button>
						</el-col>
					</el-row>
					<el-row type="flex" class="row-record record" justify="space-between" style="text-align: center">
						<el-col :span="12" :class="screen==1?'after_screen':''"><span @click="screen_assets(1)">充值记录</span></el-col>
						<el-col :span="12" :class="screen==2?'after_screen':''"><span @click="screen_assets(2)">购买资源</span></el-col>
						<el-col :span="12" :class="screen==3?'after_screen':''"><span @click="screen_assets(3)">售出资源</span></el-col>
						<el-col :span="12" :class="screen==4?'after_screen':''"><span @click="screen_assets(4)">开票记录</span></el-col>
						<el-col :span="12" :class="screen==5?'after_screen':''"><span @click="screen_assets(5)">提现记录</span></el-col>
						<el-col :span="12" :class="screen==6?'after_screen':''"><span @click="screen_assets(6)">消费记录</span></el-col>
					</el-row>
					<div v-show="screen==1">
						<el-row type="flex" justify="space-between">
							<el-table :data="Purchase" stripe style="width: 100%" :header-cell-style="{backgroundColor:'#b2b2b2', color: '#fff'}" @selection-change="handleSelectionChange" @select="select">
								<!-- <el-table-column type="selection" width="55" :selectable="selectable()"> </el-table-column> -->
								<!-- <el-table-column :selectable='selectInit' type="selection" :reserve-selection="false"></el-table-column> -->
								<el-table-column prop="charge_type" label="支付时间" align='center'>
									<template slot-scope="scope">
										<span style="margin-left: 10px">
											{{common.retun_formatDate11(scope.row.create_time)}}
										</span>
									</template>
								</el-table-column>
								<el-table-column prop="charge_type" label="支付类型" align='center'>
									<template slot-scope="scope">
										<span style="margin-left: 10px">{{scope.row.charge_type}}</span>
									</template>
								</el-table-column>
								<el-table-column prop="money" label="交易(元)" align='center'>
									<template slot-scope="scope">
										<span style="margin-left: 10px">
											{{scope.row.money}}
										</span>
									</template>
								</el-table-column>
								<el-table-column prop="money" label="状态" align='center'>成功</el-table-column>
								<el-table-column prop="goldcoin" label="金币" align='center'></el-table-column>
							</el-table>
						</el-row>
						<!--分页 -->
						<div class="paginationBox" style="margin-top:20px;text-align: center;">
							<el-pagination background layout="prev, pager, next" :total="total" :current-page="currentPage"
							 :hide-on-single-page="fenyeis" :page-size="per_page" @current-change="handleCurrentChange"></el-pagination>
							<!--total 总条目数   current-page当前页数  page-size  per_page 每页多少条  -->
						</div>
					</div>
					<div v-show="screen==2">
						<el-row type="flex" justify="space-between">
							<el-table :data="Records1" stripe style="width: 100%" :header-cell-style="{backgroundColor:'#b2b2b2', color: '#fff'}">
								<el-table-column prop="resource_id" label="ID" align='center'></el-table-column>
								<el-table-column prop="thumbnail" label="素材图片" width="180" align='center'>
									<template slot-scope="scope">
										<img :src="common.retun_img(scope.row.thumbnail)" style="width: 100%">
									</template>
								</el-table-column>
								<el-table-column prop="name" label="素材名称" align='center'></el-table-column>
								<el-table-column prop="goldcoin" label="价格(金币)" align='center'></el-table-column>
								<el-table-column prop="create_time" label="下载时间" align='center'>
									<template slot-scope="scope">
										<span style="margin-left: 10px">
											{{common.retun_formatDate11(scope.row.create_time)}}
										</span>
									</template>
								</el-table-column>
								<el-table-column label="操作" align='center'>
									<template slot-scope="scope">
										<el-row>
											<el-col>
												<el-button type="primary" size='mini' v-show='scope.row.pack_type==6' @click='download_data(scope.row)'>下载</el-button>
												<p v-show='scope.row.pack_type!=6'>请到BIM-FILM使用</p>
											</el-col>
										</el-row>
									</template>
								</el-table-column>
							</el-table>
						</el-row>
						<!--分页 -->
						<div class="paginationBox" style="margin-top:20px;text-align: center;">
							<el-pagination background layout="prev, pager, next, jumper" :total="total1" :current-page="currentPage1"
							 :hide-on-single-page="fenyeis" :page-size="per_page1" @current-change="handleCurrentChange1"></el-pagination>
							<!--total 总条目数   current-page当前页数  page-size  per_page 每页多少条  -->
						</div>
					</div>
					<div v-show="screen==3">
						<el-row type="flex" justify="space-between">
							<el-table :data="Records2" stripe style="width: 100%" :header-cell-style="{backgroundColor:'#b2b2b2', color: '#fff'}">
								<el-table-column prop="resource_id" label="ID" align='center'></el-table-column>
								<el-table-column prop="thumbnail" label="素材图片" align='center' width="180">
									<template slot-scope="scope">
										<img :src="common.retun_img(scope.row.thumbnail)" style="width: 100%">
									</template>
								</el-table-column>
								<el-table-column prop="name" label="素材名称" align='center'></el-table-column>
								<el-table-column prop="goldcoin" label="价格(金币)" align='center'></el-table-column>
								<el-table-column prop="owner_goldcoin" label="收益金币" align='center'></el-table-column>
								<el-table-column prop="create_time" label="下载时间" align='center'>
									<template slot-scope="scope">
										<span style="margin-left: 10px">
											{{common.retun_formatDate11(scope.row.create_time)}}
										</span>
									</template>
								</el-table-column>
							</el-table>
						</el-row>
						<!--分页 -->
						<div class="paginationBox" style="margin-top:20px;text-align: center;">
							<el-pagination background layout="prev, pager, next, jumper" :total="total2" :current-page="currentPage2"
							 :hide-on-single-page="fenyeis" :page-size="per_page2" @current-change="handleCurrentChange2"></el-pagination>
							<!--total 总条目数   current-page当前页数  page-size  per_page 每页多少条  -->
						</div>
					</div>
					<div v-show="screen==4">
						<el-row type="flex" justify="space-between">
							<el-table :data="Records3" stripe style="width: 100%" :header-cell-style="{backgroundColor:'#b2b2b2', color: '#fff'}">
								<el-table-column prop="account_name" label="姓名" align='center'></el-table-column>
								<el-table-column prop="money" label="金额" align='center'></el-table-column>
								<el-table-column prop="create_time" label="申请时间" align='center'>
									<template slot-scope="scope">
										<span style="margin-left: 10px">
											{{common.retun_formatDate11(scope.row.create_time)}}
										</span>
									</template>
								</el-table-column>
								<el-table-column prop="user_remarks" label="备注" align='center'></el-table-column>
								<el-table-column prop="state" label="状态" align='center'></el-table-column>
								<el-table-column label="操作">
									<template slot-scope="scope">
										<el-row>
											<el-col>
												<el-button type="primary" size='mini' @click='withdraw(scope.row)' :disabled='scope.row.state!="审核中"?true:false'>取消申请</el-button>

												<!-- -->
											</el-col>
										</el-row>
									</template>
								</el-table-column>
							</el-table>

						</el-row>
						<!--分页 -->
						<div class="paginationBox" style="margin-top:20px;text-align: center;">
							<el-pagination background layout="prev, pager, next, jumper" :total="total3" :hide-on-single-page="fenyeis"
							 :current-page="currentPage3" :page-size="per_page3" @current-change="handleCurrentChange3"></el-pagination>
							<!--total 总条目数   current-page当前页数  page-size  per_page 每页多少条  -->
						</div>
					</div>
					<div v-show="screen==5">
						<el-row type="flex" justify="space-between">
							<el-table :data="Records4" stripe style="width: 100%" :header-cell-style="{backgroundColor:'#b2b2b2', color: '#fff'}">
								<el-table-column prop="account_name" label="姓名" align='center'></el-table-column>
								<el-table-column prop="money" label="金额" align='center'></el-table-column>
								<el-table-column prop="create_time" label="申请时间" align='center'>
									<template slot-scope="scope">
										<span style="margin-left: 10px">
											{{common.retun_formatDate11(scope.row.create_time)}}
										</span>
									</template>
								</el-table-column>
								<el-table-column prop="state" label="状态" align='center'></el-table-column>
								<el-table-column label="操作">
									<template slot-scope="scope">
										<el-row>
											<el-col>
												<el-button type="primary" size='mini' @click='tixian_withdraw(scope.row)' :disabled='scope.row.state!="审核中"?true:false'>取消申请</el-button>
												<!-- -->
											</el-col>
										</el-row>
									</template>
								</el-table-column>
							</el-table>
						</el-row>
						<!--分页 -->
						<div class="paginationBox" style="margin-top:20px;text-align: center;">
							<el-pagination background layout="prev, pager, next, jumper" :total="total2" :current-page="currentPage2"
							 :hide-on-single-page="fenyeis" :page-size="per_page2" @current-change="handleCurrentChange4"></el-pagination>
							<!--total 总条目数   current-page当前页数  page-size  per_page 每页多少条  -->
						</div>
					</div>
					<div v-show="screen==6">
						<el-row type="flex" justify="space-between">
							<el-table :data="Records5" stripe style="width: 100%" :header-cell-style="{backgroundColor:'#b2b2b2', color: '#fff'}">
								<el-table-column prop="goldcoin" label="金币" align='center'></el-table-column>
								<el-table-column prop="create_time" label="消费时间" align='center'>
									<template slot-scope="scope">
										<span style="margin-left: 10px">
											{{common.retun_formatDate11(scope.row.create_time)}}
										</span>
									</template>
								</el-table-column>
								<el-table-column prop="goldcoin_type" label="状态" align='center'></el-table-column>
							</el-table>
						</el-row>
						<!--分页 -->
						<div class="paginationBox" style="margin-top:20px;text-align: center;">
							<el-pagination background layout="prev, pager, next, jumper" :total="total5" :current-page="currentPage5"
							 :hide-on-single-page="fenyeis" :page-size="per_page5" @current-change="handleCurrentChange5"></el-pagination>
							<!--total 总条目数   current-page当前页数  page-size  per_page 每页多少条  -->
						</div>
					</div>
				</el-col>
			</el-row>
			<el-dialog title="提示" :visible.sync="download_dialogVisible" width="60%">
				<template>
					<el-table :data="tableData" style="width: 100%">
						<el-table-column prop="file_mp4" label="file_mp4">
						</el-table-column>
						<el-table-column prop="file_origin" :label="filename">
						</el-table-column>
						<el-table-column>
							<template slot-scope="scope">
								<el-row>
									<el-col>
										<el-button type="primary" size='mini' @click='download_file(scope.row.file_mp4,scope.row)' :disabled='scope.row.file_mp4==null?true:false'>下载mp4</el-button>
										<el-button type="primary" size='mini' @click='download_file(scope.row.file_origin,scope.row)' :disabled='scope.row.file_origin==null?true:false'>下载{{filename}}</el-button>
									</el-col>
								</el-row>
							</template>
						</el-table-column>
					</el-table>
				</template>
				<span slot="footer" class="dialog-footer">
					<el-button @click="download_dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="download">确 定</el-button>
				</span>
			</el-dialog>
			<el-dialog title="发票申请" :visible.sync="invoice_dialogVisible" width="60%">
				<el-row>
					<!-- 	account_money_data:{
						account_money: null,
						invoice_shenhe: null,
						invoice_weikai: null,
						invoice_yikai: null,
					}, -->
					<el-col :span='6'>
						<p class="p_one"><span class="span_one">{{account_money_data.account_money}}</span>元</p>
						<p class="p_tow">共消费</p>
					</el-col>
					<el-col :span='6'>
						<p class="p_one"><span class="span_one">{{account_money_data.invoice_yikai}}</span>元</p>
						<p class="p_tow">已开发票</p>
					</el-col>
					<el-col :span='6'>
						<p class="p_one"><span class="span_one">{{account_money_data.invoice_weikai}}</span>元</p>
						<p class="p_tow">未开发票</p>
					</el-col>
					<el-col :span='6'>
						<p class="p_one"><span class="span_one">{{account_money_data.invoice_shenhe}}</span>元</p>
						<p class="p_tow">审核中</p>
					</el-col>
				</el-row>

				<el-row>
					<p style="width: 100%; line-height:50px;text-indent: 50px;">发票信息:</p>
					<el-form ref="form_invoice" :model="form_invoice" label-width="150px">
						<el-col :span='12'>
							<el-form-item label="发票类型">
								<el-select v-model="form_invoice.invoice_type" placeholder="请选择发票类型">
									<!-- <el-option label="增值税普通发票" value="1"></el-option> -->
									<!-- <el-option label="增值税专用发票" value="2"></el-option> -->
									<el-option label="电子普通发票" value="3"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span='9'>
							<el-form-item label="*纳税人识别号">
								<el-input v-model.trim="form_invoice.IdentificationNumber"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span='9'>
							<el-form-item label="*发票金额">
								<el-input disabled v-model.trim="account_money_data.invoice_weikai"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span='9' :push='3'>
							<el-form-item label="*发票抬头">
								<el-input v-model.trim="form_invoice.InvoiceTitle"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span='9'>
							<el-form-item label="地址电话">
								<el-input v-model="form_invoice.address_telephone"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span='9' :push='3'>

							<el-form-item label="开户行及账号">
								<el-input v-model="form_invoice.BankOfDeposit"></el-input>
							</el-form-item>
						</el-col>
					</el-form>
				</el-row>

				<el-row>
					<p style="width: 100%; line-height:50px;text-indent: 50px;">收票人:</p>
					<el-form ref="form_invoice" :model="form_invoice" label-width="150px">
						<el-col :span='9'>
							<el-form-item label="姓名">
								<el-input v-model="form_invoice.name"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span='9' :push='3'>
							<el-form-item label="联系手机">
								<el-input v-model="form_invoice.Telephone"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span='9'>
							<el-form-item label="收件地址">
								<el-input v-model="form_invoice.Address"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span='9' :push='3'>
							<el-form-item label="*邮箱">
								<el-input v-model="form_invoice.mailbox"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span='12'>
							<el-form-item label="备注">
								<el-input v-model="form_invoice.remarks"></el-input>
							</el-form-item>
						</el-col>
					</el-form>
				</el-row>
				<el-col style="line-height: 30px;padding-left: 30px">
					申请说明：<br>
					1、未开发票金额满<span style="color: red">¥100元</span>即可在线申请发票。<br>
					2、发票为电子普通发票（目前不支持手机短信发放，只支持邮箱发放请正确填写邮箱地址）<br>
					3、每月1号和15号为发票人工审核期（节假日顺延），审批通过后需3-7个工作日开出；<br>
					4、发票内容为“技术服务费”。<br>
					<!-- 5、如需修改账号、姓名和提现金额大于800元请电话联系我们:400-182-1204<br> -->
				</el-col>
				<span slot="footer" class="dialog-footer">
					<el-button @click="invoice_dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="ApplyInvoiceDetermine">确 定</el-button>
				</span>
			</el-dialog>



		</div>
	</div>
</template>
<script>
	import contentLeft from '@/components/contentLeft.vue';
	import {
		requestPurchase,
		requestRecords,
		requestlinvoice_list,
		requestWithdrawal_list,
		resource_download,
		requestWithdrawal_add,
		requestStorage,
		linvoice_add,
		requestSend_sms,
		requestCheck_sms,
		requestLinvoice_information,
		requestLinvoice_modify,
		requestWithdrawal_modify,
		log_goldcoin
	} from "../api/request";


	export default {
		name: 'mywallet',
		components: {
			contentLeft
		},
		data() {
			return {
				fenyeis: true,
				screen: 1,
				goldcoin: 0,
				allgoldcoin: 0,
				typeCon: true,
				Purchase: [],
				Records1: [],
				Records2: [],
				Records3: [],
				Records4: [],
				Records5: [],
				total: 100,
				currentPage: 1,
				per_page: 100,
				total1: 100,
				currentPage1: 1,
				per_page1: 100,
				total2: 100,
				currentPage2: 1,
				per_page2: 100,
				total3: 100,
				currentPage3: 1,
				per_page3: 100,
				total4: 100,
				currentPage4: 1,
				per_page4: 100,
				total5: 100,
				currentPage5: 1,
				per_page5: 100,
				download_dialogVisible: false,
				download_list: null,
				tableData: [{
					file_mp4: null,
					file_origin: null,
					resource_id: null,
					name: null
				}],
				dialogFormVisible: false,
				form: {
					account_name: '',
					iaentitycard: '',
					bank_card: null,
					bank_name: null,
					mobile: null,
					money: 0,
					verification_value: null
				},
				account_money_data: {
					account_money: null,
					invoice_shenhe: null,
					invoice_weikai: null,
					invoice_yikai: null,
				},
				formLabelWidth: '120px',
				tixian_isshow: true,
				invoice_dialogVisible: false,
				form_invoice: {
					//发票类型
					invoice_type: 1,
					//姓名
					name: null,
					//纳税人识别号
					IdentificationNumber: null,
					//金额
					AmountOfMoney: null,
					//发票抬头
					InvoiceTitle: null,
					//开户行
					BankOfDeposit: null,
					//联系电话
					Telephone: null,
					//收件地址
					Address: null,
					//邮箱
					mailbox: null,
					//备注
					remarks: null,
					//地址及电话
					address_telephone: null
				},
				filename: null,
				multipleSelection: []
			}
		},
		methods: {
			selectable(row,index){
				console.log("111")
				console.log(row,index)
			},
			handleSelectionChange(val){
				this.multipleSelection = val;
				console.log(this.multipleSelection)
			},
			select(selection, row) {
				console.log(selection,row)
				// 清除 所有勾选项
				// this.$refs.multipleTable.clearSelection()
				// // 当表格数据都没有被勾选的时候 就返回
				// // 主要用于将当前勾选的表格状态清除
				// if(selection.length == 0) return 
				// this.$refs.multipleTable.toggleRowSelection(row, true);
			},
			
			goumaijilu() {
				this.$router.push("PurchaseRecords")
			},
			screen_assets(e) {
				this.screen = e;
			},
			verification() {
				requestSend_sms(this.form.phone).then(res => {
					if (res.code == 0) {
						this.$message({
							message: '发送成功',
							type: 'success'
						});
					}
				})
			},
			//取消申请
			withdraw(item) {
				console.log(item)
				let data = {
					id: item.id
				}
				requestLinvoice_modify(data).then(res => {
					if (res.code == 0) {
						this.$message({
							message: '取消成功',
							type: 'success'
						});
						//发票申请列表
						let data = {};
						requestlinvoice_list(data).then(res => {
							this.total3 = res.ret.total;
							this.currentPage3 = res.ret.current_page;
							this.per_page3 = res.ret.per_page;
							this.Records3 = res.ret.data;
							for (let i = 0; i < res.ret.data.length; i++) {
								if (res.ret.data[i].state == 0) {
									res.ret.data[i].state = '审核中'
								} else if (res.ret.data[i].state == 1) {
									res.ret.data[i].state = '已取消'
								} else if (res.ret.data[i].state == 2) {
									res.ret.data[i].state = '成功'
								} else if (res.ret.data[i].state == 3) {
									res.ret.data[i].state = '驳回'
								}
							}

						})
					}
				})

			},
			tixian_withdraw(item) {
				console.log(item)
				let data = {
					id: item.id
				}
				requestWithdrawal_modify(data).then(res => {
					if (res.code == 0) {
						this.$message({
							message: '取消成功',
							type: 'success'
						});
						// 提现记录
						let datas = {};
						requestWithdrawal_list(datas).then(res => {
							this.total4 = res.ret.total;
							this.currentPage4 = res.ret.current_page;
							this.per_page4 = res.ret.per_page;
							this.Records4 = res.ret.data;
							for (let i = 0; i < res.ret.data.length; i++) {
								if (res.ret.data[i].state == 2) {
									res.ret.data[i].state = '受理中'
								} else if (res.ret.data[i].state == 3) {
									res.ret.data[i].state = '成功'
								} else if (res.ret.data[i].state == 4) {
									res.ret.data[i].state = '驳回'
								} else if (res.ret.data[i].state == 0) {
									res.ret.data[i].state = '审核中'
								} else if (res.ret.data[i].state == 1) {
									res.ret.data[i].state = '已取消'
								}
							}

						})
					}
				})
			},
			InvoiceApplication() {
				this.invoice_dialogVisible = true;
				this.form_invoice.invoice_type = '';
				this.form_invoice.IdentificationNumber = '';
				this.form_invoice.AmountOfMoney = '';
				this.form_invoice.InvoiceTitle = '';
				this.form_invoice.address_telephone = '';
				this.form_invoice.BankOfDeposit = '';
				this.form_invoice.name = '';
				this.form_invoice.Telephone = '';
				this.form_invoice.Address = '';
				this.form_invoice.mailbox = '';
				this.form_invoice.remarks = '';
			},
			//发票申请确定
			ApplyInvoiceDetermine() {

				// "token": "233",
				// "type_id": 1,
				// "identifier": 1,
				// "money": 1,
				// "rise": 1,
				// "address": "地址电话",
				// "account": "开户行及账号",
				// "user_name": "收票人:姓名",
				// "user_mobile": "收票人:手机号",
				// "user_address": "收票人:收件地址",
				// "user_email": "邮箱",
				// "user_remarks": "备注"
				let data = {
					type_id: this.form_invoice.invoice_type,
					identifier: this.form_invoice.IdentificationNumber,
					money: this.account_money_data.invoice_weikai,
					rise: this.form_invoice.InvoiceTitle,
					address: this.form_invoice.address_telephone,
					account: this.form_invoice.BankOfDeposit,
					user_name: this.form_invoice.name,
					user_mobile: this.form_invoice.Telephone,
					user_address: this.form_invoice.Address,
					user_email: this.form_invoice.mailbox,
					user_remarks: this.form_invoice.remarks
				};
				if (this.form_invoice.IdentificationNumber != '' && this.form_invoice.mailbox != '' &&this.account_money_data.invoice_weikai != '' && this.form_invoice.InvoiceTitle !=
					'') {
					this.invoice_dialogVisible = false;
					linvoice_add(data).then(res => {
						if (res.code == 0) {
							this.$message({
								message: '申请成功',
								type: 'warning'
							});
						}
					})
				} else {
					this.$message({
						message: '请填写必要信息',
						type: 'warning'
					});
				}

			},
			//充值记录 分页
			handleCurrentChange(val) {
				window.scrollTo(0, 0);
				requestPurchase(val).then(res => {
					this.total = res.ret.total;
					this.currentPage = res.ret.current_page;
					this.per_page = res.ret.per_page;
					this.Purchase = res.ret.data;
					for (let i = 0; i < res.ret.data.length; i++) {
						if (res.ret.data[i].charge_type == 1) {
							res.ret.data[i].charge_type = '支付宝'
						} else if (res.ret.data[i].charge_type == 2) {
							res.ret.data[i].charge_type = '微信'
						} else if (res.ret.data[i].charge_type == 3) {
							res.ret.data[i].charge_type = 'vip充值企业回退的钱'
						} else if (res.ret.data[i].charge_type == 4) {
							res.ret.data[i].charge_type = '售出资源的钱'
						} else if (res.ret.data[i].charge_type == 5) {
							res.ret.data[i].charge_type = '动画大赛获奖金币'
						} else if (res.ret.data[i].charge_type == 6) {
							res.ret.data[i].charge_type = '小程序注册获得金币'
						} else if (res.ret.data[i].charge_type == 7) {
							res.ret.data[i].charge_type = '合同赠送'
						} else if (res.ret.data[i].charge_type == 8) {
							res.ret.data[i].charge_type = '其他'
						}
					}
				});
			},
			//购买记录 分页
			handleCurrentChange1(val) {
				window.scrollTo(0, 0);
				requestRecords(1, val).then(res => {
					this.total1 = res.ret.total;
					this.currentPage1 = res.ret.current_page;
					this.per_page1 = res.ret.per_page;
					this.Records1 = res.ret.data;
				});
			},
			//购买记录 分页
			handleCurrentChange5(val) {
				window.scrollTo(0, 0);
				requestRecords(6, val).then(res => {
					this.total5 = res.ret.total;
					this.currentPage5 = res.ret.current_page;
					this.per_page5 = res.ret.per_page;
					this.Records5 = res.ret.data;
				});
			},
			//下载资源
			download_data(item) {
				this.download_dialogVisible = true;
				this.download_list = item;
				this.tableData[0].file_mp4 = this.download_list.file_mp4
				this.tableData[0].file_origin = this.download_list.file_origin
				this.tableData[0].resource_id = this.download_list.resource_id
				this.tableData[0].name = this.download_list.name;
				let aaa = this.tableData[0].file_origin.split('.')[1];
				this.filename = aaa;

			},
			download_file(file, file_list) {

				let _this = this;
				let aaa = file.split('.')[1];
				let bbb = aaa;
				let data = {
					resource_id: file_list.resource_id,
					md5: file.split('.')[0],
				};
				resource_download(data).then(res => {
					if (res.code == 0) {
						this.$message({
							message: '下载中，请稍等',
							type: 'success'
						});
						if (aaa == 'mp4') {
							_this.common.downloadfile(res.ret[0], file_list.name, bbb);
						} else {
							_this.common.modalnormaldownload(file_list.name, res.ret[0])
						}
					}

				})
			},
			download() {
				this.download_dialogVisible = false;
			},
			//出售记录 分页
			handleCurrentChange2(val) {
				window.scrollTo(0, 0);
				requestRecords(2, val).then(res => {
					this.total2 = res.ret.total;
					this.currentPage2 = res.ret.current_page;
					this.per_page2 = res.ret.per_page;
					this.Records2 = res.ret.data;
				});
			},
			//空间记录 分页
			handleCurrentChange3(val) {
				window.scrollTo(0, 0);
				requestStorage_log(val).then(res => {
					this.total3 = res.ret.total;
					this.currentPage3 = res.ret.current_page;
					this.per_page3 = res.ret.per_page;
					this.Records3 = res.ret.data;
					// this.total2=res.ret.total;
					// this.currentPage2=res.ret.current_page;
					// this.per_page2=res.ret.per_page;
					// this.Records2=res.ret.data;
				});
			},
			//会员记录 分页
			handleCurrentChange4(val) {
				window.scrollTo(0, 0);
				requestMember(val).then(res => {
					this.total2 = res.ret.total;
					this.currentPage2 = res.ret.current_page;
					this.per_page2 = res.ret.per_page;
					this.Records2 = res.ret.data;
				});
			},
			//跳转充值
			chongzhi() {
				this.$router.push({
					path: "/MyPay"
				});
			},
			tixian_btn() {

				this.$router.push({
					path: "/MyTixian"
				});
			},
			//提现
			tixian() {
				let _this = this,
					data = {};
				data = this.form;
				if (data.alipay_name == "" || data.account_ID == "" || data.mobile == "") {
					this.$message.error('请填写全部信息');
				} else if (data.money < 100) {
					this.$message.error('每次提现至少满¥100元');
				} else if (Number(data.money) > Number(this.goldcoin)) {
					this.$message.error('您的金币不足');
				} else {
					data.money = data.money / 10;

					let datas = {
						mobile: _this.form.phone,
						code: _this.form.verification_value
					}
					requestCheck_sms(datas).then(res => {
						if (res.code == 0) {
							console.log(data)
							requestWithdrawal_add(data).then(res => {
								if (res.code == 0) {
									_this.dialogFormVisible = false;
									_this.$message({
										message: '恭喜你提现成功',
										type: 'success'
									});
									history.go(0)
								}
							})
						}
					})
				}
			},
			keyup() {
				this.form.money = this.form.money.replace(/[^\.\d]/g, '');
				this.form.money = this.form.money.replace('.', '');
			}
		},
		mounted() {
			if (this.Account.GetSession() == null) {
				this.$router.push({
					path: "/"
				});
				return
			} else {
				console.log(this.Account.GetStorage().vip_type)
				if (this.Account.GetStorage().vip_type == 100) {

					this.tixian_isshow = false
				}
			}
			//充值记录
			requestPurchase().then(res => {
				this.total = res.ret.total;
				this.currentPage = res.ret.current_page;
				this.per_page = res.ret.per_page;
				this.Purchase = res.ret.data;
				for (let i = 0; i < res.ret.data.length; i++) {
					if (res.ret.data[i].charge_type == 1) {
						res.ret.data[i].charge_type = '支付宝'
					} else if (res.ret.data[i].charge_type == 2) {
						res.ret.data[i].charge_type = '微信'
					} else if (res.ret.data[i].charge_type == 3) {
						res.ret.data[i].charge_type = 'vip充值企业回退的钱'
					} else if (res.ret.data[i].charge_type == 4) {
						res.ret.data[i].charge_type = '售出资源的钱'
					} else if (res.ret.data[i].charge_type == 5) {
						res.ret.data[i].charge_type = '动画大赛获奖金币'
					} else if (res.ret.data[i].charge_type == 6) {
						res.ret.data[i].charge_type = '小程序注册获得金币'
					} else if (res.ret.data[i].charge_type == 7) {
						res.ret.data[i].charge_type = '合同赠送'
					} else if (res.ret.data[i].charge_type == 8) {
						res.ret.data[i].charge_type = '其他'
					}
				}
			});
			//购买记录
			requestRecords(1).then(res => {
				this.total1 = res.ret.total;
				this.currentPage1 = res.ret.current_page;
				this.per_page1 = res.ret.per_page;
				this.Records1 = res.ret.data;
			});
			let dataes = {}
			//购买记录
			log_goldcoin(dataes).then(res => {
				this.total5 = res.ret.total;
				this.currentPage5 = res.ret.current_page;
				this.per_page5 = res.ret.per_page;
				this.Records5 = res.ret.data;
				for (let i = 0; i < res.ret.data.length; i++) {
					if (res.ret.data[i].goldcoin_type == 4) {
						res.ret.data[i].goldcoin_type = '购买资源'
					} else if (res.ret.data[i].goldcoin_type == 6) {
						res.ret.data[i].goldcoin_type = '创建二维码'
					} else if (res.ret.data[i].goldcoin_type == 7) {
						res.ret.data[i].goldcoin_type = '二维码轻量化'
					} else if (res.ret.data[i].goldcoin_type == 8) {
						res.ret.data[i].goldcoin_type = '购买音频'
					} else if (res.ret.data[i].goldcoin_type == 9) {
						res.ret.data[i].goldcoin_type = '提现'
					}
				}

			});
			//出售记录
			requestRecords(2).then(res => {
				this.total2 = res.ret.total;
				this.currentPage2 = res.ret.current_page;
				this.per_page2 = res.ret.per_page;
				this.Records2 = res.ret.data;
			});
			//发票申请列表
			let data = {};
			requestlinvoice_list(data).then(res => {
				this.total3 = res.ret.total;
				this.currentPage3 = res.ret.current_page;
				this.per_page3 = res.ret.per_page;
				this.Records3 = res.ret.data;
				for (let i = 0; i < res.ret.data.length; i++) {
					if (res.ret.data[i].state == 0) {
						res.ret.data[i].state = '审核中'
					} else if (res.ret.data[i].state == 1) {
						res.ret.data[i].state = '已取消'
					} else if (res.ret.data[i].state == 2) {
						res.ret.data[i].state = '成功'
					} else if (res.ret.data[i].state == 3) {
						res.ret.data[i].state = '驳回'
					}
				}

			})
			// 提现记录
			let datas = {};
			requestWithdrawal_list(datas).then(res => {
				this.total4 = res.ret.total;
				this.currentPage4 = res.ret.current_page;
				this.per_page4 = res.ret.per_page;
				this.Records4 = res.ret.data;
				for (let i = 0; i < res.ret.data.length; i++) {
					if (res.ret.data[i].state == 2) {
						res.ret.data[i].state = '受理中'
					} else if (res.ret.data[i].state == 3) {
						res.ret.data[i].state = '成功'
					} else if (res.ret.data[i].state == 4) {
						res.ret.data[i].state = '驳回'
					} else if (res.ret.data[i].state == 0) {
						res.ret.data[i].state = '审核中'
					} else if (res.ret.data[i].state == 1) {
						res.ret.data[i].state = '已取消'
					}
				}

			})
			requestStorage().then(res => {
				localStorage.setItem("storage", JSON.stringify(res.ret));
				this.goldcoin = this.Account.GetStorage().resource_goldcoin;
				this.allgoldcoin = this.Account.GetStorage().goldcoin;
			})
			let datase = {}
			requestLinvoice_information(datase).then(res => {
				this.account_money_data = res.ret;
			})



		}
	}
</script>
<style scoped>
	.after_screen {
		border-bottom: 2px #007bff solid;
		color: #007bff;
	}

	.mywallet {
		width: 1350px;
		/* background-color: #d1e1f9; */
		padding: 20px;
		display: flex;
		justify-content: flex-start;
		margin: 0 auto;
	}

	.content-right {
		background-color: #fff;
		overflow: hidden;
		box-shadow: 0px 0px 6px #c4c4c4;
	}

	.con-base {
		height: 80px;
		line-height: 80px;
		font-size: 16px;

		border-bottom: 1px solid #efeff0;
		;

	}

	.con-base span {
		color: #007aff;
	}

	.con-base b {
		margin-left: 50px;
		font-size: 16px;

	}

	.con-base b span,
	.con-detail b span {
		color: #007aff;
		margin-right: 7px;
	}

	.con-base button {
		margin-top: 30px;
		margin-right: 10px;
		width: 101px;
		height: 39px;
		color: #fff;
		background-color: #007bff;
		border: 1px solid #007bff;
		border-radius: 2px;
	}

	.record {
		height: 60px;
		line-height: 60px;
		display: flex;
		justify-content: space-between;
		font-size: 12px;
	}

	.record span {
		display: block;
		height: 60px;
		line-height: 60px;
		cursor: pointer;
	}

	.span_one {
		color: red;
		font-size: 18px;
	}

	.p_one {
		text-align: center;
		color: #CCCCCC;
		font-size: 14px;
	}

	.p_tow {
		text-align: center;
		color: #CCCCCC;
		font-size: 14px;
	}

	.el-dialog {
		margin-top: 10vh !important;
	}

	>>>.after_screen {
		border-bottom: 2px rgb(203, 80, 49) solid;
		color: rgb(203, 80, 49);
	}

	>>>.con-base button {
		background-color: rgb(203, 80, 49);
		border: 1px solid rgb(203, 80, 49);
	}
</style>
